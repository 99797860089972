import React, { useEffect, useState, useRef, useMemo } from "react";
import {
	useTable,
	useGlobalFilter,
	useFilters,
	usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import profile from "../../../images/avatar/1.jpg";
import { Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import PmService from "../../../services/PmService";
import moment from "moment";
import Loader from "../Loader";
import ModalLoader from "../Loader/ModalLoader";
import Swal from "sweetalert2";
import { ColumnFilter } from "../FilteringTable/ColumnFilter";
import "../FilteringTable/filtering.css";
import { format } from "date-fns";
import { Cell } from "recharts";
import Select from "react-select";
import emojiRegex from 'emoji-regex';

function BookingDetails() {
  const COLUMNS = [
    {
        Header: 'Booking ID',
        Footer: 'Booking ID',
        accessor: 'booking_number',
        Filter: ColumnFilter,
    },
    {
        Header: 'Apartment Name',
        Footer: 'Apartment Name',
        accessor: 'property_name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Main Guest',
        Footer: 'Main Guest',
        accessor: 'main_guest_name',
        Filter: ColumnFilter,
		Cell: ({ value }) => {
            return (
                <div
                    style={{
                        padding: '8px',
                        borderRadius: '4px',
                        textAlign: 'center',
                    }}
                >
                    {value || 'N/A'}
                </div>
            );
        },
    },
    {
        Header: 'Connected To App',
        Footer: 'Connected To App',
        accessor: 'connected_to_app',
        Filter: ColumnFilter,
        Cell: ({ value }) => {
            const text = value === 1 ? 'Yes' : 'No';
            const bgColor = value === 1 ? '#d4edda' : '#f8d7da';
            const textColor = value === 1 ? '#155724' : '#721c24';

            return (
                <div
                    style={{
                        backgroundColor: bgColor,
                        color: textColor,
                        padding: '8px',
                        borderRadius: '4px',
                        textAlign: 'center',
                    }}
                >
                    {text}
                </div>
            );
        },
    },
    {
		Header: 'Check in',
		Footer: 'Check in',
		accessor: 'check_in',
		Filter: ColumnFilter,
		Cell: ({ value }) => {
			const date = value ? value.split('T')[0] : 'N/A';
			const time = value ? value.split('T')[1].split('.')[0] : '';
	
			const bgColor = value ? '#d4edda' : 'transparent';
			const textColor = value ? '#155724' : 'inherit';
	
			return (
				<div
					style={{
						backgroundColor: bgColor,
						color: textColor,
						padding: '8px',
						borderRadius: '4px',
						textAlign: 'center',
					}}
				>
					<div>{date}</div>
					<div>{time}</div>
				</div>
			);
		},
	},
	
    {
        Header: 'Check out',
        Footer: 'Check out',
        accessor: 'check_out',
        Filter: ColumnFilter,
		Cell: ({ value }) => {
			const date = value ? value.split('T')[0] : 'N/A';
			const time = value ? value.split('T')[1].split('.')[0] : '';
	
			const bgColor = value ? '#d4edda' : 'transparent';
			const textColor = value ? '#155724' : 'inherit';
	
			return (
				<div
					style={{
						backgroundColor: bgColor,
						color: textColor,
						padding: '8px',
						borderRadius: '4px',
						textAlign: 'center',
					}}
				>
					<div>{date}</div>
					<div>{time}</div>
				</div>
			);
		},
    },
    {
        Header: 'Early Check in',
        Footer: 'Early Check in',
        accessor: 'late_check_in',
        Filter: ColumnFilter,
		Cell: ({ value }) => {
			const date = value ? value.split('T')[0] : 'N/A';
			const time = value ? value.split('T')[1].split('.')[0] : '';
	
			const bgColor = value ? '#f8d7da' : 'transparent';
            const textColor = value ? '#721c24' : 'inherit';
	
			return (
				<div
					style={{
						backgroundColor: bgColor,
						color: textColor,
						padding: '8px',
						borderRadius: '4px',
						textAlign: 'center',
					}}
				>
					<div>{date}</div>
					<div>{time}</div>
				</div>
			);
		},
    },
    {
        Header: 'Late Check out',
        Footer: 'Late Check out',
        accessor: 'late_check_out',
        Filter: ColumnFilter,
		Cell: ({ value }) => {
			const date = value ? value.split('T')[0] : 'N/A';
			const time = value ? value.split('T')[1].split('.')[0] : '';
	
			const bgColor = value ? '#f8d7da' : 'transparent';
            const textColor = value ? '#721c24' : 'inherit';
	
			return (
				<div
					style={{
						backgroundColor: bgColor,
						color: textColor,
						padding: '8px',
						borderRadius: '4px',
						textAlign: 'center',
					}}
				>
					<div>{date}</div>
					<div>{time}</div>
				</div>
			);
		},
    },
  ]

  const [loader, setLoader] = useState(false);
  const [bookingList, setBookingList] = useState([]);

  const fetchBookingDetails = () => {
    PmService.GetUserBookingDetails()
      .then((resp) => {
        setBookingList(resp.data);
      })
      .catch((err) => {
        console.log("err = ", err);
      })
      .finally(function () {
        setLoader(false);
      })
  }

  useEffect(() => {
    setLoader(true);
    fetchBookingDetails()
  }, []);

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => bookingList, [bookingList]);
	const tableInstance = useTable({
		columns,
		data,
		initialState: {
		pageIndex: 0
		}
	}, useFilters, useGlobalFilter, usePagination);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance;


	const {
		globalFilter,
		pageIndex
	} = state;

	return loader ? (
		<Loader />
	) : (
    <>
		<div className="row">
			<div className="col-xl-12">
				<div className="card">
					<div className="card-body">
						<div className="table-responsive table-hover fs-14 order-list-table">
							<table {...getTableProps()} className="table filtering-table table-responsive-lg">
							<thead>
								{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
										{column.canFilter ? column.render('Filter') : null}
									</th>
									))}
								</tr>
								))}
							</thead> 
							<tbody {...getTableBodyProps()} className="" >
							
								{page.map((row) => {
								prepareRow(row)
								return(
									<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
									})}
									</tr>
								)
								})}
							</tbody>
							</table>
							<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
								{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number" 
								className="ms-2"
								defaultValue={pageIndex + 1} 
								onChange = {e => { 
									const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
									gotoPage(pageNumber)
								} } 
								/>
							</span>
							</div>
							<div className="text-center">	
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
								
								<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
								Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
								Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div >
    </>
  );
}

export default BookingDetails;
